/* Imports */
import { API_HELPER } from "@/helpers/api.js";

/* Exports */
// API services that can be invoked on the modules
export const PROPERTY_SERVICE = {
    list,
    add,
    edit,
    get,
    updateStatus,
};

/* Consts*/
// Root service to build API url
const API_ROOT_SERVICE = "properties";

/* Services */
function list(queryParams)
{
    // Build API call.
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE]);    

    const requestOptions = {
        method: 'GET',
    }; 

    // Process API call, validating the token beforehand.
    return API_HELPER.loggedApiCall(url, queryParams, requestOptions);
}
function add(property) {

    // Build API call.
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE]);    
    var raw = JSON.stringify(property);
    const requestOptions = {
        method: 'POST',
        headers: {"Content-Type":"application/json"},
        body: raw
    };

    // Process API call, validating the token beforehand.
    return API_HELPER.loggedApiCall(url, null, requestOptions);
}
function edit(property) {
    // Build API call.
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE]); 
    var raw = JSON.stringify(property);

    const requestOptions = {
        method: 'PUT',
        headers: { "Content-Type":"application/json" },
        body: raw
    };

    // Process API call, validating the token beforehand.
    return API_HELPER.loggedApiCall(url, null, requestOptions);
}

function get(id)
{
    // Build API call.
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE, id]);    
    const requestOptions = {
        method: 'GET' 
    };

    // Process API call, validating the token beforehand.
    return API_HELPER.loggedApiCall(url, null, requestOptions);
}
function updateStatus(id) {
    // Build API call.
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE, 'Status', id]); 

    const requestOptions = {
        method: 'PUT',
        
    };

    // Process API call, validating the token beforehand.
    return API_HELPER.loggedApiCall(url, null, requestOptions);
}
