import { ENVIRONMENT_HELPER } from "@/helpers/environment.js";
import store from '@/store.js'
import router from '@/router';

export const IS_LOADING_STATUS = "loading";

export const API_HELPER = {
    abortController: new AbortController(),
    pathJoin,
    loggedApiCall,
    apiCall
};

function pathJoin(parts)
{
    return parts.map((part, i) => 
    {
        if (i === 0) {
          return part.toString().trim().replace(/[\/]*$/g,''); //eslint-disable-line
        } else{
          return part.toString().trim().replace(/(^[\/]*|[\/]*$)/g,'') //eslint-disable-line
        }
    }).filter(x=>x.length).join('/');
}

/* Handles 1) request cancellation 2) refresh the token if the user is not logged in 3) fetch url 4) error handler */
async function loggedApiCall(url, queryParams, requestOptions, canBeAborted)
{
    // Validate parameters
    if(!url || !requestOptions)
    {
        throw("The necessary info for the api call was not provided!");
    }

    if(ENVIRONMENT_HELPER.isDev()) { console.log(requestOptions) }

    canBeAborted = (canBeAborted === undefined || canBeAborted === null) ? true : canBeAborted;

    // Validate if the user is logged in.
    let user = store.state.account.user;
    if(!user || !user.data || !user.data.accessToken || !user.data.accessTokenExpirationDate || !user.data.refreshToken)
    {
        throw("The user is not logged in or the current session is not valid!");
    }

    // Check if token is expired. Return authorization header with jwt token and compare expired time with the next 10 minutes. The 10 minutes is just a buffer that we allow ourselves to refresh the token. 
    var expiredTime = new Date(user.data.accessTokenExpirationDate);
    var limitTime = new Date();

    if(ENVIRONMENT_HELPER.isProd()) { limitTime.setMinutes(limitTime.getMinutes() + 10); }
    else limitTime.setMinutes(limitTime.getMinutes() + 2);
    
    var limitTimeUtc = new Date(limitTime.getUTCFullYear(), limitTime.getUTCMonth(), limitTime.getUTCDate(), limitTime.getUTCHours(), limitTime.getUTCMinutes(), limitTime.getUTCSeconds());
    var expiredTimeUtc = new Date(expiredTime.getUTCFullYear(), expiredTime.getUTCMonth(), expiredTime.getUTCDate(), expiredTime.getUTCHours(), expiredTime.getUTCMinutes(), expiredTime.getUTCSeconds());

    if(expiredTimeUtc < limitTimeUtc && store.state.account.refresh != 'refreshing')
    {       
        /* Refresh the token and try again */
        store.dispatch('account/refreshToken', null, { root: true });
    }

    // Wait for refresh token
    while(store.state.account.refresh == 'refreshing')
    {
        await sleep(100);
    }

    // Update request options authorization
    if(!requestOptions.headers) 
    {
        requestOptions.headers = {};
    }

    // return authorization header with jwt token
    user = JSON.parse(localStorage.getItem('user'));

    if (user && user.data && user.data.accessToken) {
        requestOptions.headers.Authorization = 'Bearer ' + user.data.accessToken ;
    }
    else
    {
        throw("Couldn't create the authorization header");
    }

    // Build query parameters
    var fullUrl = getFullUrl(url, queryParams);

    // Complete the request options adding signal so that the request can be aborted.
    if(canBeAborted)
    {
        requestOptions.signal = API_HELPER.abortController.signal;
    }
    
    if(ENVIRONMENT_HELPER.isDev()) { console.log(fullUrl) }

    return fetch(fullUrl, requestOptions)
    .then(response =>{
        if(ENVIRONMENT_HELPER.isDev()) { console.log(response) }
        return response.text().then(text => {
            const data = text && JSON.parse(text);
            if(ENVIRONMENT_HELPER.isDev()) { console.log(data) }
            if (!response.ok) {
                var errorMsg;
                switch(response.status)
                {
                    case 401:
                        {
                            errorMsg = "The token is not valid.";

                            store.dispatch('account/logout', null, { root: true });
                            break;
                        }
                    case 500:
                    case 501:
                    case 502:
                    case 503:
                        {
                            router.push('/error');
                            break;
                        }
                    default:
                        {
                            errorMsg = data;
                            break;
                        }
                }
                return Promise.reject(errorMsg);
            }
    
            return data;
        });        
    })
    .catch((err) => 
    {
        if (err.name === 'AbortError') 
        {
            if(ENVIRONMENT_HELPER.isDev()) { "The request was aborted!" }
        }
        else
        {
            if(err && err.Errors)
            {
                return Promise.reject(err);
            }
            else
            {
                return Promise.reject("Something went wrong, please try again. If the problem persists contact the support.");
            }
        }
    });
}

/* Handles 1) request cancellation 2) fetch url 3) error handler */
function apiCall(url, queryParams, requestOptions, canBeAborted)
{
    // Validate parameters
    if(!url || !requestOptions)
    {
        throw("The necessary info for the api call was not provided!");
    }

    if(ENVIRONMENT_HELPER.isDev()) { console.log(requestOptions) }

    canBeAborted = (canBeAborted === undefined || canBeAborted === null) ? true : canBeAborted;

    // Build query parameters
    var fullUrl = getFullUrl(url, queryParams);

    // Complete the request options adding signal so that the request can be aborted.
    if(canBeAborted)
    {
        requestOptions.signal = API_HELPER.abortController.signal;
    }

    if(ENVIRONMENT_HELPER.isDev()) { console.log(fullUrl) }

    return fetch(fullUrl, requestOptions)
    .then(response =>{
        if(ENVIRONMENT_HELPER.isDev()) { console.log(response) }
        return response.text().then(text => {
            const data = text && JSON.parse(text);
            if(ENVIRONMENT_HELPER.isDev()) { console.log(data) }
            if (!response.ok) {
                var errorMsg;
                switch(response.status)
                {
                    case 401:
                        {
                            errorMsg = "The token is not valid.";

                            store.dispatch('account/logout', null, { root: true });
                            break;
                        }
                    case 500:
                    case 501:
                    case 502:
                    case 503:
                        {
                            router.push('/error');
                            break;
                        }
                    default:
                        {
                            errorMsg = data;
                            break;
                        }
                }
                return Promise.reject(errorMsg);
            }
            return data;
        });        
    })
    .catch((err) => 
    {
        if (err.name === 'AbortError') 
        {
            if(ENVIRONMENT_HELPER.isDev()) { "The request was aborted!" }
        }
        else
        {
            if(err && err.Errors)
            {
                return Promise.reject(err);
            }
            else
            {
                return Promise.reject("Something went wrong, please try again. If the problem persists contact the support.");
            }
        }
    });
}

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

function getFullUrl(url, queryParams)
{
    var fullUrl = encodeURI(url);
    if(queryParams)
    {
        fullUrl += "?";
        for(var key in queryParams) 
        {
            var value = queryParams[key];

            if(Array.isArray(value))
            {
                value.forEach(function(item){
                    var encodedItem = encodeURIComponent(item);
                    fullUrl += `&${key}=${encodedItem}`;    
                });
            }
            else if(value || value == 0)
            {
                var encodedValue = encodeURIComponent(value);

                fullUrl += `&${key}=${encodedValue}`;
            }
        }
    }

    return fullUrl;
}