import Vue from 'vue'
import Router from 'vue-router'

// Containers
const Container = () => import('@/containers/Container')

const Login = () => import('@/views/Login')


const Dashboard = () => import('@/views/dashboard/Dashboard')

const PropertiesList = () => import('@/views/properties/PropertiesList')
const AddProperty = () => import('@/views/properties/AddProperty')
const ViewProperty = () => import('@/views/properties/ViewProperty')
const EditProperty = () => import('@/views/properties/EditProperty')

const Statistics = () => import('@/views/statistics/Statistics')

const UsersList = () => import('@/views/userManagement/UsersList')
const AddUser = () => import('@/views/userManagement/AddUser')
const EditUser = () => import('@/views/userManagement/EditUser')

const AlertsList = () => import('@/views/alerts/AlertsList')
const AddAlert = () => import('@/views/alerts/AddAlert')
const EditAlert = () => import('@/views/alerts/EditAlert')

const ConfirmUser = () => import('@/views/userManagement/ConfirmUser')


Vue.use(Router)

export default new Router({
  mode: 'history', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

function configRoutes () {
  return [
    {
      path: '/',
      redirect: '/painel',
      name: 'Home',
      component: Container,
      children: [
        {
          path: 'painel',
          name: 'Dashboard',
          component: Dashboard
        },
        {
          path: 'imoveis',
          redirect: '/imoveis/lista',
          name: 'PropertiesList',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'lista',
              name: 'PropertiesList',
              component: PropertiesList
            },
            {
              path: 'adicionar',
              name: 'AddProperty',
              component: AddProperty
            },
            {
              path: 'ver',
              name: 'ViewProperty',
              component: ViewProperty
            },
            {
              path: 'editar',
              name: 'EditProperty',
              component: EditProperty
            },
          ]
        },
        {
          path: 'estatisticas',
          redirect: '/estatisticas/grafico',
          name: 'Statistics',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'grafico',
              name: 'Statistics',
              component: Statistics
            },
          ]
        },
        {
          path: 'utilizadores',
          redirect: '/utilizadores/lista',
          name: 'UsersList',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'lista',
              name: 'UsersList',
              component: UsersList
            },
            {
              path: 'adicionar',
              name: 'AddUser',
              component: AddUser
            },
            {
              path: 'editar',
              name: 'EditUser',
              component: EditUser
            },
          ]
        },
        {
          path: 'alertas',
          redirect: '/alertas/lista',
          name: 'AlertsList',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'lista',
              name: 'AlertsList',
              component: AlertsList
            },
            {
              path: 'adicionar',
              name: 'AddAlert',
              component: AddAlert
            },
            {
              path: 'editar',
              name: 'EditAlert',
              component: EditAlert
            },
          ]
        },
      ]
    },
    {
    path: '/ConfirmarConta',
    name: 'ConfirmUser',
    component: ConfirmUser,
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    // {
    //   path: '/privacypolicy',
    //   name: 'PrivacyPolicy',
    //   component: PrivacyPolicy
    // },
    // {
    //   path: '/404',
    //   name: '404',
    //   component: Page404
    // },    
    // {
    //   path: '/500',
    //   name: '500',
    //   component: Page500
    // }, 
  ]
}

