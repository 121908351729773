import { CASHFLOWS_SERVICE } from "@/services/cashFlows.service.js";

const state = { listOutput:{}, cashFlowOutput: {}, processTask: false};

const actions = {
    addCashFlow({ dispatch, commit }, cashFlow) {
        dispatch('alert/clear', null, { root: true });
        commit('addRequest');

        CASHFLOWS_SERVICE.add(cashFlow)
            .then(
                output => {
                    commit('addSuccess', output);
                },
                error => {
                    commit('addFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    getList({ dispatch, commit }, inputGetCashFlows) {
        dispatch('alert/clear', null, { root: true });
        commit('listRequest');
        
        CASHFLOWS_SERVICE.list(inputGetCashFlows)
            .then(
                output => {
                    commit('listSuccess', output);
                },
                error => {
                    commit('listFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    editCashFlow({ dispatch, commit }, cashFlow) {
        dispatch('alert/clear', null, { root: true });
        commit('editRequest');
        
        CASHFLOWS_SERVICE.edit(cashFlow)
            .then(
                output => {
                    commit('editSuccess', output);
                },
                error => {
                    commit('editFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    getListRecords({ dispatch, commit }, inputGetCashFlows) {
        dispatch('alert/clear', null, { root: true });
        commit('listRequest');
        
        CASHFLOWS_SERVICE.listRecords(inputGetCashFlows)
            .then(
                output => {
                    commit('listSuccess', output);
                },
                error => {
                    commit('listFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    editCashFlowRecord({ dispatch, commit }, cashFlow) {
        dispatch('alert/clear', null, { root: true });
        commit('editRequest');
        
        CASHFLOWS_SERVICE.editRecord(cashFlow)
            .then(
                output => {
                    commit('editSuccess', output);
                },
                error => {
                    commit('editFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    editStatus({ dispatch, commit }, id) {
        dispatch('alert/clear', null, { root: true });
        commit('changeStatusRequest');
        
        CASHFLOWS_SERVICE.editStatus(id)
            .then(
                output => {
                    commit('changeStatusSuccess', output);
                },
                error => {
                    commit('changeStatusFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    removeCashFlowRecord({ dispatch, commit }, id) {
        dispatch('alert/clear', null, { root: true });
        commit('removeRequest');

        CASHFLOWS_SERVICE.removeRecord(id)
            .then(
                output => {
                    commit('removeSuccess', output);
                },
                error => {
                    commit('removeFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    clear({ dispatch, commit }) {
        dispatch('alert/clear', null, { root: true });
        commit('clearRequest');
    },
};

const mutations = {
    /* List */
    listRequest() { 
    },
    listSuccess(state, output) {
        state.listOutput = output;
    },
    listFailure(state) {
        state.listOutput = false;
    },
     /* Add */
     addRequest(state) {
        state.processTask = null;
    },
    addSuccess(state) {
        state.processTask = true;
    },
    addFailure(state) {
        state.processTask = false;
    },
    /* Edit */
    editRequest(state) {
        state.processTask = null;
    },
    editSuccess(state) {
        state.processTask = true;
    },
    editFailure(state) {
        state.processTask = false;

    },
    /* Change Status */
    changeStatusRequest(state) {
        state.processTask = null;
    },
    changeStatusSuccess(state) {
        state.processTask = true;
    },
    changeStatusFailure(state) {
        state.processTask = false;
    },
    /* Remove */
    removeRequest() {
        state.processTask = null;
    },
    removeSuccess(state, output) {
        state.processTask = output;
    },
    removeFailure(state) {
        state.processTask = {};
    },
    /* Clear */
    clearRequest(state) {
        // state.listOutput = {};
        state.cashFlowOutput = {};
        state.processTask = false;
    },
};


export const cashFlows = {
    namespaced: true,
    state,
    actions,
    mutations
};