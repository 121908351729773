<!-- Parent control for all pop ups. -->
<template>
    <!-- use the modal component, pass in the prop -->
    <div class="modal-mask">
        <div class="modal-wrapper d-flex align-items-center justify-content-center">
        <div class="modal-container d-flex flex-column">
            <div class="close-modal p-3">
                <img @click="closePopUp" src="@/assets/dashboard/iconClose.png" class="cursor-pointer align-self-start"/>
            </div>
            <div class="modal-body">
                <slot name="content">
                    <!-- Content goes here -->
                </slot>
            </div>
            <div class="modal-footer">
                <slot name="footer">
                    <!-- buttons goes here -->
                </slot>
            </div>
        </div>
        </div>
    </div>
</template>
<script>

export default {
    data() {
      return {  
        drag: false
      }
    },
    model: 
    {
        prop: 'showModal',
        event: 'showModalChanged'
    },
    props:
    {
        showModal: null
    },
    methods: {   
        closePopUp()
        {
            this.$emit("detailsClosed");
        },   
        handleMouseUp(evt) {
            if (!this.drag && evt.target.classList.contains('modal-wrapper')) {
                this.closePopUp();
            }

            this.drag = false;
        }
    },
    mounted() {
        document.addEventListener('mousedown', () => this.drag = false);
        document.addEventListener('mousemove', () => this.drag = true);
        document.addEventListener('mouseup', this.handleMouseUp);
    },
    destroyed() {
        document.removeEventListener('mousedown', () => this.drag = false);
        document.removeEventListener('mousemove', () => this.drag = true);
        document.removeEventListener('mouseup', this.handleMouseUp);
    },
    watch: {
        showModal: function(val)
        {
            if(val)
            {
                document.body.classList.add("modal-open");
            }
            else
            {
                document.body.classList.remove("modal-open");
            }
        }
    }

};
</script>

<style scoped>
/* Base */
.modal-mask 
{
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity .3s ease;
}

.modal-wrapper 
{
    /* Allow for scrolls on small devices */
    top: 0;
    bottom: 0;
    position: fixed;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
}

.modal-container 
{
    max-width: 450px;
    min-width: 400px;
    height: auto;
    background-color: #fff;
    transition: all .3s ease;
    border-radius: 8px;
    box-shadow: 0 0 8px 0 #303942;
}
.modal-footer{
    justify-content:flex-end;
}

/* Close */
.close-modal
{
    width: 100%;
    text-align: right;
}

.close-modal > img
{
    width: 20px;
}
@media (max-width:575px) {
    .modal-container{
        width: 100%;
        min-width: unset;
    }
}
</style>