import Vue from 'vue'
import Vuex from 'vuex'
import { alert } from './store/alert.module';
import { account } from './store/account.module';
import { statistics } from './store/statistics.module';
import { properties } from './store/property.module';
import { modules } from './store/modules.module';
import { cashFlows } from './store/cashFlows.module';
import { alerts } from './store/alerts.module';

Vue.use(Vuex)

export default new Vuex.Store({
  namespaced: true,
  modules: {
    alert,
    account,
    statistics,
    properties,
    modules,
    cashFlows,
    alerts
  }
});