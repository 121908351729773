/* Imports */
import { API_HELPER } from "@/helpers/api.js";

/* Exports */
// API services that can be invoked on the modules
export const STATISTICS_SERVICE = {
    getStatic,
    getDynamic,
    getGraph,
};

/* Consts*/
// Root service to build API url
const API_ROOT_SERVICE = "statistics";

/* Services */
function getStatic()
{
    // Build API call.
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE, "static"]);    

    const requestOptions = {
        method: 'GET',
        
    }; 

    // Process API call, validating the token beforehand.
    return API_HELPER.loggedApiCall(url, null, requestOptions);
}

function getDynamic(queryParams)
{
    // Build API call.
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE, "dynamic"]);    

    const requestOptions = {
        method: 'GET',
        
    }; 

    // Process API call, validating the token beforehand.
    return API_HELPER.loggedApiCall(url, queryParams, requestOptions);
}

function getGraph(queryParams)
{
    // Build API call.
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE, "graph"]);    

    const requestOptions = {
        method: 'GET',
        
    }; 

    // Process API call, validating the token beforehand.
    return API_HELPER.loggedApiCall(url, queryParams, requestOptions);
}
