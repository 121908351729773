/* Imports */
import { API_HELPER } from "@/helpers/api.js";

/* Exports */
// API services that can be invoked on the modules
export const CASHFLOWS_SERVICE = {
    list,
    add,
    edit,
    listRecords,
    editRecord,
    editStatus,
    removeRecord
};

/* Consts*/
// Root service to build API url
const API_ROOT_SERVICE = "CashFlows";

/* Services */
function add(cashFlow) {

    // Build API call.
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE]);    
    var raw = JSON.stringify(cashFlow);
    const requestOptions = {
        method: 'POST',
        headers: {"Content-Type":"application/json"},
        body: raw
    };

    // Process API call, validating the token beforehand.
    return API_HELPER.loggedApiCall(url, null, requestOptions);
}

function list(queryParams)
{
    // Build API call.
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE]);    

    const requestOptions = {
        method: 'GET',
        
    }; 

    // Process API call, validating the token beforehand.
    return API_HELPER.loggedApiCall(url, queryParams, requestOptions);
}

function edit(cashFlow) {
    // Build API call.
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE]); 
    var raw = JSON.stringify(cashFlow);

    const requestOptions = {
        method: 'PUT',
        headers: { "Content-Type":"application/json" },
        body: raw
    };

    // Process API call, validating the token beforehand.
    return API_HELPER.loggedApiCall(url, null, requestOptions);
}

function listRecords(queryParams)
{
    // Build API call.
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE, "records"]);    

    const requestOptions = {
        method: 'GET',
        
    }; 

    // Process API call, validating the token beforehand.
    return API_HELPER.loggedApiCall(url, queryParams, requestOptions);
}

function editRecord(cashFlow) {
    // Build API call.
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE, "records"]); 
    var raw = JSON.stringify(cashFlow);

    const requestOptions = {
        method: 'PUT',
        headers: { "Content-Type":"application/json" },
        body: raw
    };

    // Process API call, validating the token beforehand.
    return API_HELPER.loggedApiCall(url, null, requestOptions);
}

function editStatus(id)
{
    // Build API call.
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE, "status", id]);    
    var raw = JSON.stringify(id);

    const requestOptions = {
        method: 'PUT',
        headers: {"Content-Type":"application/json"},
        body: raw
    }; 

    // Process API call, validating the token beforehand.
    return API_HELPER.loggedApiCall(url, null, requestOptions);
}

function removeRecord(id)
{
    // Build API call.
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE, "records", id]);    

    const requestOptions = {
        method: 'DELETE'
    }; 

    // Process API call, validating the token beforehand.
    return API_HELPER.loggedApiCall(url, null, requestOptions);
}