import { PROPERTY_SERVICE } from "@/services/property.service.js";

const state = { listOutput:{}, propertyOutput: {}, processTask: false};

const actions = {
    getList({ dispatch, commit }, inputgetPropertys) {
        dispatch('alert/clear', null, { root: true });
        commit('listRequest');
        
        PROPERTY_SERVICE.list(inputgetPropertys)
            .then(
                output => {
                    commit('listSuccess', output);
                },
                error => {
                    commit('listFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    editProperty({ dispatch, commit }, property) {
        dispatch('alert/clear', null, { root: true });
        commit('editRequest');
        
        PROPERTY_SERVICE.edit(property)
            .then(
                output => {
                    commit('editSuccess', output);
                },
                error => {
                    commit('editFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    addProperty({ dispatch, commit }, property) {
        dispatch('alert/clear', null, { root: true });
        commit('addRequest');

        PROPERTY_SERVICE.add(property)
            .then(
                output => {
                    commit('addSuccess', output);
                },
                error => {
                    commit('addFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    getProperty({ dispatch, commit }, id) {
        dispatch('alert/clear', null, { root: true });
        commit('getRequest');

        PROPERTY_SERVICE.get(id)
            .then(
                output => {
                    commit('getSuccess', output);
                },
                error => {
                    commit('getFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    updateStatus({ dispatch, commit }, id) {
        dispatch('alert/clear', null, { root: true });
        commit('updateStatusRequest');

        PROPERTY_SERVICE.updateStatus(id)
            .then(
                output => {
                    commit('updateStatusSuccess', output);
                },
                error => {
                    commit('updateStatusFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    }
};

const mutations = {
    /* List */
    listRequest() {
    },
    listSuccess(state, output) {
        state.listOutput = output;
    },
    listFailure(state) {
        state.listOutput = false;
    },
     /* Add */
     addRequest(state) {
        state.processTask = null;
    },
    addSuccess(state) {
        state.processTask = true;
    },
    addFailure(state) {
        state.processTask = false;

    },
    /* Edit */
    editRequest(state) {
        state.processTask = null;
    },
    editSuccess(state) {
        state.processTask = true;
    },
    editFailure(state) {
        state.processTask = false;

     },
    /* Get */
    getRequest(state) {
        state.propertyOutput = null;
    },
    getSuccess(state, output) {
        state.propertyOutput = output;
    },
    getFailure(state) {
        state.propertyOutput = {};
    },
      /* Update status */
      updateStatusRequest(state) {
        state.processTask = null;
    },
    updateStatusSuccess(state) {
        state.processTask = true;
    },
    updateStatusFailure(state) {
        state.processTask = false;
    },
};


export const properties = {
    namespaced: true,
    state,
    actions,
    mutations
};