import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import store from './store'
import { API_HELPER } from "@/helpers/api.js";
import CoreuiVue from '@coreui/vue'
import Donut from 'vue-css-donut-chart';
import 'vue-css-donut-chart/dist/vcdonut.css';
import CoreuiVueCharts from '@coreui/vue-chartjs'

// CoreUI Graph
Vue.use(CoreuiVueCharts)
import GraphSlider from 'vue-graph-slider';
import 'vue-graph-slider/dist/graph-slider.css';

// DonutGraph
Vue.use(Donut);

// Graph slider
Vue.component(GraphSlider.name, GraphSlider);

// CoreUI
Vue.config.performance = true
Vue.use(CoreuiVue)

// JQuery
import 'jquery/src/jquery.js'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/css/bootstrap.css'
import "bootstrap-icons/font/bootstrap-icons.css";

// Vuelidate
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

// VueSocialChat
import VueSocialChat from 'vue-social-chat'
Vue.use(VueSocialChat)

// VuePhoneNumber Plugin
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

Vue.component('vue-phone-number-input', VuePhoneNumberInput);


router.beforeEach((to, from, next) => {
  // cancel pending requests and reset controller
  API_HELPER.abortController.abort();
  API_HELPER.abortController = new AbortController();

  // if logged out, process request
  const logoutPages = ['/logout'];
  if (logoutPages.includes(to.path)) {
    store.dispatch('account/logout', null, { root: true });
    return;
  }

  const loggedIn = localStorage.getItem('user');
  const publicPages = ['/login', '/reset-password', '/ConfirmarConta'];

  // redirect to login page if not logged in and trying to access a restricted page
  const authRequired = !publicPages.includes(to.path);

  if (authRequired && !loggedIn) {
    return next('/login');
  }

  // redirect to dashboard to dashboard if logged in and trying to acess logged out pages
  const authNotRequired = publicPages.includes(to.path);
  if (authNotRequired && loggedIn) {
    return next('/');
  }



  next();
})

router.beforeEach((to, from, next) => {
  console.log(to);
  console.log(from);

  if(to.name != "Home") // Home has special rules to run this code
  {
    setTimeout(function () {
      window.scrollTo(0, 0);
    },100);
  }

  next();
})

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
