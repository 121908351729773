import { ALERTS_SERVICE } from "@/services/alerts.service.js";

const state = { listOutput: {}, alertOutput: {}, processTask: false };

const actions = {
    getAlertList({ dispatch, commit }, params) {
        dispatch('alert/clear', null, { root: true });
        commit('listRequest');
        
        ALERTS_SERVICE.list(params)
            .then(
                output => {
                    commit('listSuccess', output);
                },
                error => {
                    commit('listFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    createAlert({ dispatch, commit }, alert) {
        dispatch('alert/clear', null, { root: true });
        commit('createRequest');
        
        ALERTS_SERVICE.create(alert)
            .then(
                output => {
                    commit('createSuccess', output);
                },
                error => {
                    commit('createFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    getAlert({ dispatch, commit }, id) {
        dispatch('alert/clear', null, { root: true });
        commit('getRequest');
        
        ALERTS_SERVICE.get(id)
            .then(
                output => {
                    commit('getSuccess', output);
                },
                error => {
                    commit('getFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    editAlert({ dispatch, commit }, alert) {
        dispatch('alert/clear', null, { root: true });
        commit('editRequest');
        
        ALERTS_SERVICE.edit(alert)
            .then(
                output => {
                    commit('editSuccess', output);
                },
                error => {
                    commit('editFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    removeAlert({ dispatch, commit }, id) {
        dispatch('alert/clear', null, { root: true });
        commit('removeAlertRequest');
        
        ALERTS_SERVICE.remove(id)
            .then(
                output => {
                    commit('removeAlertSuccess', output);
                },
                error => {
                    commit('removeAlertFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
};

const mutations = {
    /* Get */
    listRequest() {
    },
    listSuccess(state, output) {
        state.listOutput = output;
    },
    listFailure(state) {
        state.listOutput = {};
    },
    /* Create */
    createRequest(state) {
        state.processTask = null;
    },
    createSuccess(state) {
        state.processTask = true;
    },
    createFailure(state) {
        state.processTask = false;
    },
    /* Get */
    getRequest(state) {
        state.alertOutput = null;
    },
    getSuccess(state, output) {
        state.alertOutput = output;
    },
    getFailure(state) {
        state.alertOutput = false;
    },
    /* Edit */
    editRequest(state) {
        state.processTask = null;
    },
    editSuccess(state) {
        state.processTask = true;
    },
    editFailure(state) {
        state.processTask = false;
    },
    /* Remove */
    removeAlertRequest(state) {
        state.processTask = null;
    },
    removeAlertSuccess(state) {
        state.processTask = true;
    },
    removeAlertFailure(state) {
        state.processTask = false;
    },
};


export const alerts = {
    namespaced: true,
    state,
    actions,
    mutations
};