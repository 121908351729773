import { MODULES_SERVICE } from "@/services/modules.service.js";

const state = { listOutput: {}, moduleOutput: {}, processTask: false };

const actions = {
    getList({ dispatch, commit }, params) {
        dispatch('alert/clear', null, { root: true });
        commit('listRequest');
        
        MODULES_SERVICE.list(params)
            .then(
                output => {
                    commit('listSuccess', output);
                },
                error => {
                    commit('listFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    createModule({ dispatch, commit }, module) {
        dispatch('alert/clear', null, { root: true });
        commit('createRequest');
        
        MODULES_SERVICE.create(module)
            .then(
                output => {
                    commit('createSuccess', output);
                },
                error => {
                    commit('createFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    updateModule({ dispatch, commit }, params) {
        dispatch('alert/clear', null, { root: true });
        commit('editRequest');
        
        MODULES_SERVICE.edit(params)
            .then(
                output => {
                    commit('editSuccess', output);
                },
                error => {
                    commit('editFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    getModule({ dispatch, commit }, id) {
        dispatch('alert/clear', null, { root: true });
        commit('getRequest');
        
        MODULES_SERVICE.get(id)
            .then(
                output => {
                    commit('getSuccess', output);
                },
                error => {
                    commit('getFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    changeVisibility({ dispatch, commit }, id) {
        dispatch('alert/clear', null, { root: true });
        commit('changeVisibilityRequest');
        
        MODULES_SERVICE.changeVisibility(id)
            .then(
                output => {
                    commit('changeVisibilitySuccess', output);
                },
                error => {
                    commit('changeVisibilityFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    addStatus({ dispatch, commit }, params) {
        dispatch('alert/clear', null, { root: true });
        commit('changeStatusRequest');
        
        MODULES_SERVICE.addStatus(params)
            .then(
                output => {
                    commit('changeStatusSuccess', output);
                },
                error => {
                    commit('changeStatusFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    editStatus({ dispatch, commit }, params) {
        dispatch('alert/clear', null, { root: true });
        commit('changeStatusRequest');
        
        MODULES_SERVICE.editStatus(params)
            .then(
                output => {
                    commit('changeStatusSuccess', output);
                },
                error => {
                    commit('changeStatusFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    removeStatus({ dispatch, commit }, id) {
        dispatch('alert/clear', null, { root: true });
        commit('changeStatusRequest');
        
        MODULES_SERVICE.removeStatus(id)
            .then(
                output => {
                    commit('changeStatusSuccess', output);
                },
                error => {
                    commit('changeStatusFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
};

const mutations = {
    /* Get */
    listRequest() {
    },
    listSuccess(state, output) {
        state.listOutput = output;
    },
    listFailure(state) {
        state.listOutput = false;
    },
    /* Create */
    createRequest(state) {
        state.processTask = null;
    },
    createSuccess(state) {
        state.processTask = true;
    },
    createFailure(state) {
        state.processTask = false;
    },
    /* Edit */
    editRequest(state) {
        state.processTask = null;
    },
    editSuccess(state) {
        state.processTask = true;
    },
    editFailure(state) {
        state.processTask = false;
    },
    /* Get */
    getRequest(state) {
        state.moduleOutput = null;
    },
    getSuccess(state, output) {
        state.moduleOutput = output;
    },
    getFailure(state) {
        state.moduleOutput = false;
    },
    /* Change Visibility */
    changeVisibilityRequest(state) {
        state.processTask = null;
    },
    changeVisibilitySuccess(state) {
        state.processTask = true;
    },
    changeVisibilityFailure(state) {
        state.processTask = false;
    },
    /* Change Status */
    changeStatusRequest(state) {
        state.processTask = null;
    },
    changeStatusSuccess(state) {
        state.processTask = true;
    },
    changeStatusFailure(state) {
        state.processTask = false;
    },
};


export const modules = {
    namespaced: true,
    state,
    actions,
    mutations
};