import { STATISTICS_SERVICE } from "@/services/statistics.service.js";

const state = { staticOutput: {}, dynamicOutput: {}, graphOutput:{} };

const actions = {
    getStatic({ dispatch, commit }) {
        dispatch('alert/clear', null, { root: true });
        commit('staticRequest');
        
        STATISTICS_SERVICE.getStatic()
            .then(
                output => {
                    commit('staticSuccess', output);
                },
                error => {
                    commit('staticFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    getDynamic({ dispatch, commit }, queryParams) {
        dispatch('alert/clear', null, { root: true });
        commit('dynamicRequest');
        
        STATISTICS_SERVICE.getDynamic(queryParams)
            .then(
                output => {
                    commit('dynamicSuccess', output);
                },
                error => {
                    commit('dynamicFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    getGraph({ dispatch, commit }, queryParams) {
        dispatch('alert/clear', null, { root: true });
        commit('graphRequest');
        
        STATISTICS_SERVICE.getGraph(queryParams)
            .then(
                output => {
                    commit('graphSuccess', output);
                },
                error => {
                    commit('graphFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
};

const mutations = {
    /* Static */
    staticRequest(state) {
        state.staticOutput = null;
    },
    staticSuccess(state, output) {
        state.staticOutput = output;
    },
    staticFailure(state) {
        state.staticOutput = false;
    },
    /* Dyamic */
    dynamicRequest(state) {
        state.dynamicOutput = null;
    },
    dynamicSuccess(state, output) {
        state.dynamicOutput = output;
    },
    dynamicFailure(state) {
        state.dynamicOutput = false;
    },
    /* Graph */
    graphRequest(state) {
        state.graphOutput = null;
    },
    graphSuccess(state, output) {
        state.graphOutput = output;
    },
    graphFailure(state) {
        state.graphOutput = false;
    },
};


export const statistics = {
    namespaced: true,
    state,
    actions,
    mutations
};