<template>
  <router-view ref="container" @success-alert-requested="showSuccessAlert" @show-warning-alert="showWarningAlert"></router-view>
</template>

<script>
import ResultPopup from '@/controls/popups/ResultPopup.vue';
import Vue from "vue";
import EventBus from '@/events/bus.js';

const SUCCESS = 0;
const WARNING = 1;

export default {
  name: 'App',
  created() {
    EventBus.$on('success-alert-requested', this.showSuccessAlert)
    EventBus.$on('warning-alert-requested', this.showWarningAlert)
  },
  destroyed() {
    EventBus.$on('success-alert-requested')
    EventBus.$on('warning-alert-requested')
  },
  methods: {
    showSuccessAlert({message, primaryButton, primaryCallback, secondaryButton, secondaryCallback}){
      this.showAlert(SUCCESS, message, primaryButton, primaryCallback, secondaryButton, secondaryCallback);
    },
    showWarningAlert({message, primaryButton, primaryCallback, secondaryButton, secondaryCallback}){
      this.showAlert(WARNING, message, primaryButton, primaryCallback, secondaryButton, secondaryCallback);
    },
    showAlert(type, message, primaryButton, primaryCallback, secondaryButton, secondaryCallback)
    {
      var ComponentClass = Vue.extend(ResultPopup)
      var instance = new ComponentClass({
        propsData: {type: type, message: message, primaryButtonText: primaryButton, primaryButtonCallback: primaryCallback, secondaryButtonText: secondaryButton, secondaryButtonCallback: secondaryCallback }
      })
      instance.$mount() // pass nothing
      this.$refs.container.$el.appendChild(instance.$el)
    }
  },
}
</script>


<style>
html, body, #app
{
  min-height: 600px;
  max-width: 100vw;
  height: 100%;
  background-color: rgb(239, 239, 239) !important;
}
b
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* Colors */
.primary-text-color,
::placeholder,
a
{
  color: rgb(78, 84, 200);
}

a:hover,
.secondary-text-color
{
  color: rgb(143, 148, 251);
}

.btn-primary
{
  background-color: rgb(78, 84, 200) !important;
  color: white !important;
}

.btn-primary:hover
{
  background-color: white!important;
  border: 1px solid rgb(78, 84, 200)!important;
  color: rgb(78, 84, 200) !important;
}

.btn-outline-secondary
{
  border-color: white !important;
  color: white !important;
}

.btn-outline-secondary:hover
{
  background-color: white !important;
  color: rgb(78, 84, 200) !important;
}

/* Global classes */
.disabled
{
  pointer-events: none;
  opacity: 0.9;
}

a,
.cursor-pointer
{
  cursor: pointer;
}

hr
{
  width: 100%;
}

.font-weight-bold
{
  font-weight: bold;
}

/* Skeleton loading */
.skeleton-box {
  position: relative;
  overflow: hidden;
  vertical-align: middle;
  background-color: whitesmoke !important;
}

.skeleton-box .skeleton-box-hide
{
  display: none !important;
}

.skeleton-box::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(
    90deg,
    rgba(#fff, 0) 0,
    rgba(#fff, 0.2) 20%,
    rgba(#fff, 0.5) 60%,
    rgba(#fff, 0)
  );
  animation: shimmer 5s infinite;
  content: '';
}
.vdp-datepicker__calendar{
  width: 100%!important;
}
.vdp-datepicker__calendar .cell{
  font-size: .7rem!important;
}
.vdp-datepicker__calendar .cell.selected{
  background-color: rgb(78, 84, 200)!important;
  color: white!important;
}
.vdp-datepicker__calendar .cell.selected:hover{
  background-color: rgb(78, 84, 200)!important;
}
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).month:hover{
  border: 1px solid rgb(78, 84, 200)!important;
}
.vdp-datepicker__calendar-button{
  position: absolute;
  right: 5%;
  height: 100%;
  display: flex;
  align-items: center;
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

</style>
