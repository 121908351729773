import { USER_SERVICE } from "@/services/user.service.js";
import router from '@/router/index';

const user = JSON.parse(localStorage.getItem('user'));
const state = user
    ? { status: { loggedIn: true }, user,  listOutput: {}, processTask: undefined, refresh: undefined }
    : { status: {}, user: null,  listOutput: {}, processTask: undefined, refresh: undefined };

const actions = {
    login({ dispatch, commit }, { username, password }) {
        dispatch('alert/clear', null, { root: true });
        commit('loginRequest', { username });
        
        USER_SERVICE.login(username, password)
            .then(
                user => {
                    user.data.email = username;
                    commit('loginSuccess', user);
                    router.push('/');
                },
                error => {
                    commit('loginFailure', user);                
                    dispatch('alert/error', error, { root: true });
                }

            );
    },
    updatePassword({ dispatch, commit }, password) {
        dispatch('alert/clear', null, { root: true });
        commit('updatePasswordRequest');
        
        USER_SERVICE.updatePassword(password)
            .then(
                output => {
                    commit('updatePasswordSuccess', output);
                },
                error => {
                    commit('updatePasswordFailure', user);                
                    dispatch('alert/error', error, { root: true });

                }

            );
    },
    requestLinkPassword({ dispatch, commit }, email) {
        dispatch('alert/clear', null, { root: true });
        commit('linkRequest');

        USER_SERVICE.requestLink(email)
            .then(
                output => {
                    commit('linkSuccess', output);
                },
                error => {
                    commit('linkFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    resetPassword({ dispatch, commit }, {username, password, token}) {
        dispatch('alert/clear', null, { root: true });
        commit('resetPasswordRequest');

        USER_SERVICE.resetPassword(username, password, token)
            .then(
                output => {
                    commit('resetPasswordSuccess', output);
                },
                error => {
                    commit('resetPasswordFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    listUsers({ dispatch, commit }, inputGetUser) {
        dispatch('alert/clear', null, { root: true });
        commit('listRequest');
        
        USER_SERVICE.listUsers(inputGetUser)
            .then(
                output => {
                    commit('listSuccess', output);
                },
                error => {
                    commit('listFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    updateUser({ dispatch, commit }, email) {
        dispatch('alert/clear', null, { root: true });
        commit('updateUserRequest');

        USER_SERVICE.updateUser(email)
            .then(
                output => {
                    commit('updateUserSuccess', output);
                },
                error => {
                    commit('updateUserFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    updateStatus({ dispatch, commit }, email) {
        dispatch('alert/clear', null, { root: true });
        commit('updateStatusRequest');

        USER_SERVICE.updateStatus(email)
            .then(
                output => {
                    commit('updateStatusSuccess', output);
                },
                error => {
                    commit('updateStatusFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    addUser({ dispatch, commit }, newUser) {
        dispatch('alert/clear', null, { root: true });
        commit('addUserRequest');

        USER_SERVICE.addUser(newUser)
            .then(
                output => {
                    commit('addUserSuccess', output);
                },
                error => {
                    commit('addUserFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },

    confirmAccount({ dispatch, commit }, {password, username, token}) {
        dispatch('alert/clear', null, { root: true });
        commit('confirmAccountRequest');

        USER_SERVICE.confirmAccount(password, username, token)
            .then(
                output => {
                    commit('confirmAccountSuccess', output);
                },
                error => {
                    commit('confirmAccountFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    requestToken({ dispatch, commit }, {username}) {
        dispatch('alert/clear', null, { root: true });
        commit('requestTokenRequest');

        USER_SERVICE.requestToken(username)
            .then(
                output => {
                    commit('requestTokenSuccess', output);
                },
                error => {
                    commit('requestTokenFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    refreshToken({ dispatch, commit }) {
        dispatch('alert/clear', null, { root: true });
        commit('refreshRequest');

       /* Refresh the token and try again */
       USER_SERVICE.refreshToken(state.user.data.refreshToken, state.user.data.email)
       .then(user =>{
            commit('refreshSuccess', user);
       })
       .catch((error) => 
       {
            commit('refreshFailure', error);
            dispatch('alert/error', error, { root: true });

            dispatch('account/logout', null, { root: true });
       });
    },
    logout({ dispatch, commit }) {
        dispatch('alert/clear', null, { root: true });
        commit('logout');

        USER_SERVICE.logout();
    }
};

const mutations = {
    /* Login */
    loginRequest(state, user) {
        state.status = { loggingIn: true };
        state.user = user;
    },
    loginSuccess(state, user) {
        state.status = { loggedIn: true };
        state.user = user;

        // login successful if there's a jwt token in the response
        if (user.data && user.data.refreshToken) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(user));
        }
    },
    loginFailure(state) {
        state.status = {};
        state.user = null;
    },
    /* Update password */
    updatePasswordRequest(state) {
        state.processTask = null;
    },
    updatePasswordSuccess(state) {
        state.processTask = true;
    },
    updatePasswordFailure(state) {
        state.processTask = false;
    },
    /* Request link */
    linkRequest(state) {
        state.processTask = undefined;
    },
    linkSuccess(state) {
        state.processTask = true;
    },
    linkFailure(state) {
        state.processTask = false;
    },
    /* Reset password */
    resetPasswordRequest(state) {
        state.processTask = null;
    },
    resetPasswordSuccess(state) {
        state.processTask = true;
    },
    resetPasswordFailure(state) {
        state.processTask = false;
    },
    /* List User */
    listRequest() { 
    },
    listSuccess(state, output) {
        state.listOutput = output;
    },
    listFailure(state) {
        state.listOutput = false;
    },
    /* Update user */
    updateUserRequest(state) {
        state.processTask = null;
    },
    updateUserSuccess(state) {
        state.processTask = true;
    },
    updateUserFailure(state) {
        state.processTask = false;
    },
    /* Update status */
    updateStatusRequest(state) {
        state.processTask = null;
    },
    updateStatusSuccess(state) {
        state.processTask = true;
    },
    updateStatusFailure(state) {
        state.processTask = false;
    },
    /* Add user */
    addUserRequest(state) {
        state.processTask = null;
    },
    addUserSuccess(state) {
        state.processTask = true;
    },
    addUserFailure(state) {
        state.processTask = false;
    },
    /* confirmAccount */
    confirmAccountRequest(state) {
        state.processTask = null;
    },
    confirmAccountSuccess(state) {
        state.processTask = true;
    },
    confirmAccountFailure(state) {
        state.processTask = false;
    },
    /* requestToken */
    requestTokenRequest(state) {
        state.processTask = null;
    },
    requestTokenSuccess(state) {
        state.processTask = true;
    },
    requestTokenFailure(state) {
        state.processTask = false;
    },
    /* refreshToken */
    refreshRequest(state) {
        state.refresh = 'refreshing';
    },
    refreshSuccess(state, user) {
        state.user = user;

        // login successful if there's a jwt token in the response
        if (user.data && user.data.refreshToken) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(user));
        }

        state.refresh = true;
    },
    refreshFailure(state) {
        state.refresh = false;
    },
    /* */
    logout(state) {
        state.status = {};
        state.user = null;

        localStorage.removeItem('user');
        localStorage.removeItem('locale');

        router.go('/login'); // Force all modules to reset state
    }
};

export const account = {
    namespaced: true,
    state,
    actions,
    mutations
};