<template>
    <BaseAlert @detailsClosed="closeConfirmRequested">
        <template v-slot:content>
            <div class="d-flex flex-column justify-content-center align-items-center">
                <i class="py-2" :class="icon"></i>
                <h5 class="text-center mb-0 py-2" v-html="message"/>
            </div>
        </template>
        <template v-slot:footer>
            <button class="btn btn-primary mx-2 px-3" @click="primaryButtonClicked">{{primaryButtonText}}</button>
            <button class="btn btn-secondary mx-2 px-3" @click="secondaryButtonClicked" v-if="secondaryButtonText">{{secondaryButtonText}}</button>
        </template>
    </BaseAlert>
</template>

<script>
import BaseAlert from './BaseAlert.vue';

const SUCCESS = 0;
const WARNING = 1;

export default {
    components: {
        BaseAlert,
    },
    props: {
        type: 
        {
            type: Number,
            default: SUCCESS
        },
        message: String,
        primaryButtonText:
        {
            type: String,
            default: "Sim"
        },
        primaryButtonCallback: { type: Function },
        secondaryButtonText:
        {
            type: String,
            default: null
        },
        secondaryButtonCallback: { type: Function }
    },
    methods:
    {
        primaryButtonClicked: function()
        {
            this.$emit("primary-click");
            if(this.primaryButtonCallback) this.primaryButtonCallback();

            this.$destroy();
            this.$el.parentNode.removeChild(this.$el);
        },
        secondaryButtonClicked: function()
        {
            this.$emit("secondary-click");
            if(this.secondaryButtonCallback) this.secondaryButtonCallback();

            this.$destroy();
            this.$el.parentNode.removeChild(this.$el);
        },
        closeConfirmRequested()
        {       
            this.$destroy();
            this.$el.parentNode.removeChild(this.$el);
        },
    },
    computed: {
        icon: function()
        {
            var result = null;

            switch(this.type)
            {
                case SUCCESS:
                {
                    result = "bi bi-check-circle";
                    break;
                }
                case WARNING:
                {
                    result = "bi bi-exclamation-circle";
                    break;
                }
                default:
                {
                    throw("Type is not supported");
                }
            }
            return result;
        }
    },
}
</script>

<style scoped>
.bi-exclamation-circle::before,
.bi-check-circle::before{
    font-size: 5rem;
}
.bi-exclamation-circle::before
{
    color: #f8bb86;
}
.bi-check-circle::before
{
    color: #a5dc86;
}
</style>