/* Imports */
import { API_HELPER } from "@/helpers/api.js";
import { ENVIRONMENT_HELPER } from "@/helpers/environment.js";

/* Exports */
// API services that can be invoked on the modules
export const USER_SERVICE = {
    login,
    updatePassword,
    requestLink,
    resetPassword,
    listUsers,
    updateUser,
    updateStatus,
    addUser,
    confirmAccount,
    requestToken,
    logout,
    refreshToken
};

/* Consts*/
// Root service to build API url
const API_ROOT_SERVICE = "Users";

/* Services */
function login(username, password) 
{
    // Mandatory parameters
    if(!username || !password)
    {
        return Promise.reject("The parameters are not complete!");
    }

    //

    var raw = JSON.stringify({username, password});

    const requestOptions = {
        method: 'POST',
        headers: {"Content-Type":"application/json"},
        body: raw
    };

    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE, "Login"]);

    return processLogin(url, requestOptions);
}

function updatePassword(password) 
{
    // Mandatory parameters
    if(!password)
    {
        return Promise.reject("The parameters are not complete!");
    }

    //

    const requestOptions = {
        method: 'PUT',
        headers: {"Content-Type":"application/json" },
        body: `"${password}"`
    };

    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE, "Password", "Update"]);

    return API_HELPER.loggedApiCall(url, null, requestOptions);    
}

function requestLink(email) 
{
    // Mandatory parameters
    if(!email)
    {
        return Promise.reject("The parameters are not complete!");
    }

    //

    const requestOptions = {
        method: 'GET',
    };

    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE, "Reset", email]);

    return API_HELPER.apiCall(url, null, requestOptions);
}

function resetPassword(username, password, token) 
{
    // Mandatory parameters
    if(!username || !password || !token)
    {
        return Promise.reject("The parameters are not complete!");
    }

    //

    var raw = JSON.stringify({username, password, token});
    const requestOptions = {
        method: 'PUT',
        headers: { "Content-Type":"application/json" },
        body: raw
    };

    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE, "Reset"]);

    return API_HELPER.apiCall(url, null, requestOptions);    
}

function processLogin(url, requestOptions)
{
    return fetch(encodeURI(url), requestOptions)
    .then(response =>{
        if(ENVIRONMENT_HELPER.isDev()) console.log(response);

        return response.text().then(text => {
            const data = text && JSON.parse(text);
            if(ENVIRONMENT_HELPER.isDev()) console.log(data);

            if (!response.ok) {
                return Promise.reject(data);
            }
    
            var user = data;

            // login successful if there's a jwt token in the response
            if (user && user.data && user.data.refreshToken) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user));
            }
        
            return user;
        });        
    });
}

function listUsers(queryParams)
{
    const requestOptions = {
        method: 'GET',
    };    
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE]);
    return API_HELPER.loggedApiCall(url, queryParams, requestOptions);
}

function updateUser(user) {
   
    // Build API call.
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE]); 
    var raw = JSON.stringify(user);
    
    const requestOptions = {
        method: 'PUT',
        headers: { "Content-Type":"application/json" },
        body: raw
    };

    // Process API call, validating the token beforehand.
    return API_HELPER.loggedApiCall(url, null, requestOptions);
}

function updateStatus(email) {
   
    // Build API call.
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE, "status", email]); 
    
    const requestOptions = {
        method: 'PUT',
        
    };

    // Process API call, validating the token beforehand.
    return API_HELPER.loggedApiCall(url, null, requestOptions);
}

function addUser(user) {

    // Build API call.
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE]);    
    var raw = JSON.stringify(user);

    const requestOptions = {
        method: 'POST',
        headers: {"Content-Type":"application/json"},
        body: raw
    };

    // Process API call, validating the token beforehand.
    return API_HELPER.loggedApiCall(url, null, requestOptions);
}

function confirmAccount(password, username, token) 
{
    // Mandatory parameters
    if(!password || !username || !token)
    {
        return Promise.reject("The parameters are not complete!");
    }

    //

    var raw = JSON.stringify({password, username, token});
    const requestOptions = {
        method: 'PUT',
        headers: { "Content-Type":"application/json" },
        body: raw
    };

    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE, "ConfirmAccount"]);

    return API_HELPER.apiCall(url, null, requestOptions);    
}
function requestToken(username) 
{
    // Mandatory parameters
    if(!username)
    {
        return Promise.reject("The parameters are not complete!");
    }

    //

    const requestOptions = {
        method: 'GET',
    };

    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE, "ConfirmAccount", username]);

    return API_HELPER.apiCall(url, null, requestOptions);
}
function refreshToken(token, userEmail)
{
    // Mandatory parameters
    if(!token || !userEmail)
    {
        return Promise.reject("The parameters are not complete!");
    }

    //

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({token, userEmail})
    };

    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE, "Refresh"]);

    return API_HELPER.apiCall(url, null, requestOptions);
}

function logout() {
    // TODO Integrate logout service
    return true;
}